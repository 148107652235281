<template>
  <div class="box">
    <div class="top">
      <el-input
        v-model="input"
        prefix-icon="el-icon-search"
        placeholder="请输入内容"
      ></el-input>
    </div>
    <div class="content">
      <div v-for="(item, index) in list" :key="index" class="list_style" @click="toMap(item)">
        <div class="list_top">
          <div class="top_left">运单号：{{ item.id }}</div>
          <div class="list_right" v-show="item.state == 0">派送中</div>
          <div class="list_right" v-show="item.state == 1">已签收</div>
        </div> 
        <div class="bom">
          <div class="left">
            <div>{{ item.mailingPoint }}</div>
            <div>{{ item.sender }}</div>
          </div>
          <div class="middle">
            <div class="title">寄往</div>
            <div class="imgs">
              <img src="../../assets/h5/right.png" />
            </div>
          </div>
          <div class="right">
            <div>{{ item.pickupPoint }}</div>
            <div>{{ item.addressee }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
      list: [
        {
          id: "60923056456345",
          state: 0,
          mailingPoint: "福州市", //寄件点
          sender: "小朱", //寄件人
          pickupPoint: "杭州市", //收件点
          addressee: "张小帅", //收件人
        },
        {
          id: "60923056456345",
          state: 0,
          mailingPoint: "福州市", //寄件点
          sender: "小朱", //寄件人
          pickupPoint: "杭州市", //收件点
          addressee: "张小帅", //收件人
        },
      ],
    };
  },methods:{
    toMap(item){
        localStorage.setItem('expressDateils',JSON.stringify(item))
        this.$router.push('/waybillDetails')
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  width: 100vw;
  height: 100vh;
  background: #f7f8fa;
  .top {
    width: 100%;
    height: 72px;
    background: #ffffff;
    padding: 22px 15px;
  }
  .content {
    .list_style {
      background: #ffffff;
      width: 90%;
      margin: 0 auto;
      margin-top: 16px;
      box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.01);
      border-radius: 5px;
      .list_top {
        display: flex;
        justify-content: space-between;
        padding: 16px 18px;
        border-bottom: 1px solid #f7f8fa;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        .top_left{
            color: #666666;
        }
        .list_right {
          color: #0167ff;
        }
      }
      .bom {
        height: 88px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        .left,
        .right {
          div {
            &:first-child {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #000000;
            }
            &:nth-child(2) {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #999999;
              margin-top: 11px;
            }
          }
        }
        .left {
          margin-left: 47px;
        }
        .right {
          margin-right: 47px;
        }
        .middle {
          .title {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.7);
          }
          .imgs {
            width: 45px;
            height: 6px;
            margin-top: -12px;
            img {
              width: 45px;
              height: 6px;
            }
          }
        }
      }
    }
  }
}
.top ::v-deep .el-input__inner {
  border-radius: 20px !important;
  height: 35px !important;
  background: #f5f5f5 !important;
}
.top ::v-deep .el-input__icon {
  line-height: 37px !important;
}
</style>
